<template>
    <div class="verify-email-page">
        <div class="container my-5">
            <h1>Potwierdzenie rejestracji</h1>
            
            <font-awesome-icon v-if="inProgress"  :icon="['fas', 'spinner']" class="fa-spin" />
            <div v-if="success">
                <p>Twój email został potwierdzony</p>
                <p>Możesz się teraz zalogować</p>
                <center>
                    <router-link :to="{name: 'Login'}">Zaloguj</router-link>
                </center>
            </div>
            <b-alert variant="danger" :show="isError" fade v-html="errorAlert"></b-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: 'verifyEmail',
    data() {
        return {
            token: this.$route.params.token,
            inProgress: true,
            success: false,
            isError: false,
            errorAlert: '',
        }
    },
    created() {
        this.sendToVerification();
    },
    methods: {
        errorAlertShow(error) {
            this.isError = true;
            this.errorAlert = error;
        },
        sendToVerification(){
            this.$store.dispatch('auth/verifyEmail', this.token)
                .then( () => {
                    this.inProgress = false;
                    this.success = true;
                    this.$router.push( {name: "OrderAdd"} );
                }).catch( error =>  {
                    this.inProgress = false;
                    this.errorAlertShow( error );
                });
        }
    }

}
</script>
